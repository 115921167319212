<template>
  <div>
    <van-sticky>
      <van-cell>
        合集：
        <!-- <van-tag :plain="tag1"
                 type="primary"
                 size="large"
                 @click="tagClick(1)">Go视界</van-tag>&nbsp;
        <van-tag :plain="tag2"
                 type="primary"
                 size="large"
                 @click="tagClick(2)">Go博文</van-tag>&nbsp; -->
        <van-tag :plain="tag3"
                 type="primary"
                 size="large"
                 @click="tagClick(3)">Go宝典</van-tag>&nbsp;
        <van-tag :plain="tag4"
                 type="warning"
                 size="large"
                 @click="tagClick(4)">我的订阅</van-tag>
      </van-cell>
      <!-- Go视界分类切换 -->
      <van-tabs @click="onClick">
        <van-tab v-for="(item, index) in category"
                 :key="index">
          <template #title>
            <div class="category-item">
              <span>
                {{ item.value }}
              </span>
              <div>
                {{ item.label }}
              </div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-row style="background-color: white; border: 1px dashed #ebedf0"
             v-for="(item, index) in collectionList"
             :key="index"
             @click="goto(item.id)">
      <van-col span="9">
        <div style="
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-left: 10px;
          ">
          <van-image width="120px"
                     height="160px"
                     fit="cover"
                     :src="item.coverUrl" />
        </div>
      </van-col>
      <van-col span="15">
        <van-cell-group>
          <van-cell size="large">
            <template #title>
              <van-row justify="space-around">
                <van-col span="20">
                  <van-tag size="medium"
                           type="primary">{{ item.tag }}</van-tag>
                  <span class="custom-title"> {{ item.title }}</span>
                </van-col>
              </van-row>
            </template>

            <template #label>
              <div class="collection_desc">
                {{ item.brief }}
              </div>
            </template>
          </van-cell>
          <van-cell>
            <van-tag style="margin-left: 5px"
                     plain
                     size="medium"
                     type="primary">🙅 {{ item.author }}</van-tag>
            <van-tag style="margin-left: 10px"
                     plain
                     size="medium"
                     type="warning"
                     v-if="item.subscribeStatus == 1 || item.subscribeStatus == 3">
              已订阅
            </van-tag>
            <van-tag style="margin-left: 10px"
                     plain
                     size="medium"
                     type="warning"
                     v-if="item.subscribeStatus == 0 || item.subscribeStatus ==2">
              未订阅
            </van-tag>
          </van-cell>
        </van-cell-group>
      </van-col>
    </van-row>

    <van-empty description="欢迎投稿丰富此版块"
               v-if="collectionList.length === 0" />

    <!-- Go合集分页 -->
    <van-pagination v-model="currentPage"
                    :page-count="pageCount"
                    :items-per-page="itemsPerPage"
                    @change="handleChange"
                    mode="simple" />
  </div>
</template>

<script>
import {
  Dialog,
  Sticky,
  Pagination,
  Image as VanImage,
  Card,
  Tag,
  Tab,
  Tabs,
  Popup,
  NavBar,
  Button,
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Empty,
} from "vant";

const axios = require("axios");

export default {
  name: 'Collectionlist',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Sticky.name]: Sticky,
    [Pagination.name]: Pagination,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
  },
  data () {
    return {
      id: 1,
      //分页
      currentPage: 1,
      totalItems: 18,
      itemsPerPage: 10,
      pageCount: 3,
      //查询条件form
      queryform: {
        type: 2,
        category: "",
        pageNo: 1,
        pageSize: 10,
      },
      // Go合集分类
      category: [
        { label: "全部", value: "🔵" },
        { label: "Go基础", value: "📚" },
        { label: "Go标准库", value: "📦" },
        { label: "Go第三方库", value: "🗳️" },
        { label: "Go源码", value: "🕹️" },
        { label: "Go资讯", value: "📰" },
        { label: "Go面试", value: "🚀" },
        { label: "Go Web", value: "🕸️" },
        { label: "Go工具链", value: "🔗" },
        { label: "Go实战", value: "⚔️" },
        { label: "Go开发工具", value: "🧰" },
        { label: "Go代码分享", value: "🎭" },
        { label: "云原生", value: "☁️" },
        { label: "Go公司", value: "🏦" },
        { label: "其他", value: "🛸" },
      ],
      //题目列表
      collectionList: [],
      collectionDetail: {
        title: "《Go语言教程》",
        brief:
          " 实战从零构建一个论坛 AI, redis, mysql, sqlitPI, redis, mysql, sqlite, email, jwt.实战从零构建一个论坛 API, redis, mysql, sqlite, email, jwt.",
        tag: "推荐",
        auhtor: "码神之路",
        coverUrl:
          "https://image-1302243118.cos.ap-beijing.myqcloud.com/test/test.jpg",
        createTime: "2023-06-08",
        subscribeStatus: 0,
        collectionChapters: [
          {
            chapterName: "第一章：基础信息",
            collectionChapterRelations: [
              {
                titleAlias: "1.1 序言",
                url: "https://m.golangroadmap.com/#/sharevideo?id=27",
              },
              {
                titleAlias: "1.2 如何阅读本书",
                url: "https://m.golangroadmap.com/#/sharevideo?id=28",
              },
            ],
          },
          {
            chapterName: "第二章：进阶",
            collectionChapterRelations: [
              {
                titleAlias: "2.1 序言",
                url: "https://m.golangroadmap.com/#/sharevideo?id=29",
              },
              {
                titleAlias: "2.2 如何阅读本书",
                url: "https://m.golangroadmap.com/#/sharevideo?id=30",
              },
            ],
          },
        ],
      },

      valineOptions: {
        appId: "kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz",
        appKey: "nscorEe7TaRyn1DBFNicKsWH",
        placeholder:
          "欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。",
        path: window.location.pathname,
      },
      tag1: true,
      tag2: true,
      tag3: false,
      tag4: true,
    };
  },
  created () {
    this.search(1);
  },
  methods: {
    tagClick (val) {
      switch (val) {
        // case 1:
        //   this.tag1 = false;
        //   this.tag2 = true;
        //   this.tag3 = true;
        //   this.tag4 = true;
        //   this.queryform.type = 0;
        //   break;
        // case 2:
        //   this.tag1 = true;
        //   this.tag2 = false;
        //   this.tag3 = true;
        //   this.tag4 = true;
        //   this.queryform.type = 1;
        //   break;
        case 3:
          this.tag1 = true;
          this.tag2 = true;
          this.tag3 = false;
          this.tag4 = true;
          this.queryform.type = 2;
          break;
        case 4:
          this.tag1 = true;
          this.tag2 = true;
          this.tag3 = true;
          this.tag4 = false;
          this.queryform.type = 3;
          break;
        default:
          this.tag1 = true;
          this.tag2 = true;
          this.tag3 = false;
          this.tag4 = true;
          this.queryform.type = 2;
          break;
      }

      this.search(1);
    },
    //点击头像进入服务页
    txClick () {
      this.$router.push("home");
    },
    goto (val) {
      this.$router.push("/collectiondetail?id=" + val);
    },
    //查询Go视界数据
    search (val) {
      //this.loading = true;
      this.collectionList = [];

      if (typeof val === "undefined") {
        this.queryform.pageNo = 1;
        this.currentPage = 1;
      } else {
        this.queryform.pageNo = val;
        this.currentPage = val;
      }

      axios
        .post("https://api.golangroadmap.com/collection/page", this.queryform)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.collectionList = response.data.data.list;
            this.totalItems = response.data.data.count;
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage);
          } else if (response.status == "200" && response.data.code == "102") {
            Dialog.confirm({
              title: "提示",
              message:
                "您还没有登录，请先登录后使用Go合集功能，点击确定前往登录页。",
            })
              .then(() => {
                this.$router.push("login");
              })
              .catch(() => {
                // on cancel
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //分页切换
    handleChange (page) {
      this.search(page);
    },
    //Go视界导航切换
    onClick (name) {
      if (name === 0) {
        this.queryform.category = "";
      } else {
        this.queryform.category = this.category[name].label;
      }
      this.search(1);
    },
  },
};
</script>

<style lang="less">
.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.collection_desc {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 2px;
  height: 80px;
}
</style>
