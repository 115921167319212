<template>
  <div>
    <!-- 头部轮播图 -->

    <!-- <van-swipe :autoplay="3000"
               style="padding-top: 4px;">
      <van-swipe-item v-for="(item, index) in images"
                      :key="index">
        <div @click="goto(item.url)">
          <van-image radius="4px"
                     :src="item.image"
                     alt="" />
        </div>
      </van-swipe-item>
    </van-swipe> -->

    <van-notice-bar v-if="showBanner" color="#1989fa"
                    background="#ecf9ff"
                    left-icon="volume-o"
                    mode="closeable"
                    text="您可以在星球电脑端投稿Go语言相关的视频或文章，我们将为您提供宣传推广和付费支持。" />
    <!-- Go视界分类切换 -->
    <van-tabs style="padding-top: 4px;"
              @click="onClick">
      <van-tab v-for="(item, index) in category"
               :key="index">

        <template #title>

          <div class="category-item">
            <span>
              {{ item.value }}
            </span>
            <div>
              {{ item.label }}
            </div>

          </div>
        </template>
      </van-tab>
    </van-tabs>
    <van-loading style="padding: 90px; 0"
                 v-if="loading"
                 color="#0094ff"
                 size="24px"
                 vertical>加载中...</van-loading>

    <van-empty description="欢迎投稿丰富此版块"
               v-if="list.length  === 0 && loading === false" />

    <!-- Go视界列表 -->
    <van-grid style="padding-top: 8px;"
              :gutter="4"
              :column-num="2">
      <van-grid-item v-for="(item,index) in list"
                     :key="index">

        <div class="grid-item"
             @click="handleClick(item)">
          <van-image radius="4px"
                     :src="item.imgUrl"
                     alt=""
                     fit="fill"
                     class="grid-img" />
          <div class="van-multi-ellipsis--l2 grid-title">
            <!-- 这是一段最多显示两行的文字，多余的内容会被省略 -->
            {{ item.videoDesc }}
          </div>

          <div style="margin:10px; 10px;">
            <van-tag plain
                     size="medium"
                     type="primary">🙅 {{item.author}}</van-tag>
          </div>
        </div>

      </van-grid-item>
    </van-grid>
    <!-- Go视界分页 -->
    <van-pagination v-model="currentPage"
                    :page-count="pageCount"
                    :items-per-page="itemsPerPage"
                    @change="handleChange"
                    mode="simple" />

    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="videoDetialshow">

      <video-detail get-container="#app"
                    :videoDetail="videoDetail" />

    </van-popup>

  </div>
</template>

<script>
import { Loading, NoticeBar, Popup, Pagination, Grid, GridItem, Image, Swipe, SwipeItem, Col, Row, Icon, Tag, Tab, Tabs, Empty } from 'vant';
import VideoDetail from './VideoDetail.vue';
const axios = require("axios");
export default {
  components: {
    [Loading.name]: Loading,
    VideoDetail,
    [NoticeBar.name]: NoticeBar,
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Pagination.name]: Pagination,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data () {

    return {
      showBanner:true,
      //列表加载
      loading: false,
      // Go视界分类
      category: [
        { label: "全部", value: "🔵" },
        { label: "Go基础", value: "📚" },
        { label: "Go标准库", value: "📦" },
        { label: "Go第三方库", value: "🗳️" },
        { label: "Go源码", value: "🕹️" },
        { label: "Go资讯", value: "📰" },
        { label: "Go面试", value: "🚀" },
        { label: "Go Web", value: "🕸️" },
        { label: "Go工具链", value: "🔗" },
        { label: "Go实战", value: "⚔️" },
        { label: "Go开发工具", value: "🧰" },
        { label: "Go代码分享", value: "🎭" },
        { label: "云原生", value: "☁️" },
        { label: "Go公司", value: "🏦" },
        { label: "其他", value: "🛸" },
      ],
      //头部轮播图
      images: [
        { image: 'https://image-1302243118.cos.ap-beijing.myqcloud.com/img/gtxkwxban.jpg', url: "https://www.golangroadmap.com/trainingCamp/goSystemCourse" },
        { image: 'https://image-1302243118.cos.ap-beijing.myqcloud.com/img/nltswxbn.jpg', url: "https://www.golangroadmap.com/trainingCamp/promote" },
      ],
      //Go视界列表
      list: [
        {
          "author": "码神之路",
          "content": "",
          "createTime": "2023-06-04",
          "feature": "Go基础",
          "id": 27,
          "imgUrl": "https://image-1302243118.cos.ap-beijing.myqcloud.com/upload/files/299-e9b29d97-8bbd-4617-b372-b7becba87e7c.jpg",
          "pushUid": 3832,
          "status": 1,
          "videoDesc": "【最新go入门教程】02-为什么要使用go",
          "videoSort": 0,
          "videoUrl": "//player.bilibili.com/player.html?aid=271284576&bvid=BV1gc411N7kD&cid=1132901622&page=1&high_quality=1&autoplay=0"
        }
      ],
      //分页
      currentPage: 1,
      totalItems: 18,
      itemsPerPage: 10,
      pageCount: 3,
      //Go视界查询条件form
      queryform: {
        feature: '',
        pageNo: 1,
        pageSize: 10
      },
      videoDetialshow: false,
      videoDetail: {
        "author": "码神之路",
        "content": "",
        "createTime": "2023-06-04",
        "feature": "Go基础",
        "id": 27,
        "imgUrl": "https://image-1302243118.cos.ap-beijing.myqcloud.com/upload/files/299-e9b29d97-8bbd-4617-b372-b7becba87e7c.jpg",
        "pushUid": 3832,
        "status": 1,
        "videoDesc": "【最新go入门教程】02-为什么要使用go",
        "videoSort": 0,
        "videoUrl": "//player.bilibili.com/player.html?aid=271284576&bvid=BV1gc411N7kD&cid=1132901622&page=1&high_quality=1&autoplay=0"
      },
    };
  },
  mounted () {
    // 显示弹出框
    this.showBanner = true;
    // 5 秒后自动隐藏
    setTimeout(() => {
      this.showBanner = false;
    }, 5000);
  },
  created () {
    this.search(1)
  },
  methods: {

    //Go视界导航切换
    onClick (name) {
      if (name === 0) {
        this.queryform.feature = ""
      } else {
        this.queryform.feature = this.category[name].label
      }

      this.search(1)
    },


    //查询Go视界数据
    search (val) {

      this.loading = true;
      this.list = [];

      if (typeof val === 'undefined') {
        this.queryform.pageNo = 1
        this.currentPage = 1
      } else {
        this.queryform.pageNo = val
        this.currentPage = val
      }

      axios
        .post("https://api.golangroadmap.com/getVideoInfoByPage", this.queryform)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data.list
            this.totalItems = response.data.data.count
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //分页切换
    handleChange (page) {
      this.search(page)
    },
    handleClick (item) {
      if(item.content === undefined)    {
        item.content = "";
      }
      // debugger
      Object.assign(this.videoDetail, item);
      this.videoDetialshow = true
    },

    goto (val) {
      window.open(val, '_blank');
    }
  }
};
</script>

<style>
/* .van-tabs__line {
  background-color: #39a9ed;
} */

.my-swipe .van-swipe-item {
  color: #fff;
  width: 300px;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.van-grid-item__content {
  padding: 0;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

.grid-img {
  align-items: center;
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
}

.grid-title {
  padding: 0 7px;
  font-size: 14px;

  height: 40px;
}
</style>