<template>
  <div style="background-color: white;">
    <van-sticky :offset-top="50">
      <van-cell size="large">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-row justify="space-around">

            <van-col span="20">
              <span class="custom-title">{{blogDetails.title}}</span>
            </van-col>

            <van-col span="4"
                     style="text-align: right;">
              <van-icon name="share"
                        color="#1989fa"
                        @click="onClickShare" />

            </van-col>
          </van-row>
        </template>

        <template #label>

          <van-tag style="margin-left:5px"
                   plain
                   size="medium"
                   type="primary">🙅 {{blogDetails.author}}</van-tag>
          <van-tag style="margin-left:10px"
                   plain
                   size="medium"
                   type="warning">
            🕜 {{blogDetails.createTime}}</van-tag>
          <van-tag v-if="blogDetails.scenes == 1"
                   style="margin-left:10px"
                   plain
                   size="medium"
                   type="success">
            💎 精选</van-tag>

        </template>
      </van-cell>
    </van-sticky>

    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="blogDetails.content != null"
             class="markdown-body">
          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="blogDetails.content"></viewer>
        </div>
        <div v-else>
          <van-empty description="文档整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Dialog, ShareSheet, Sticky, Divider, Empty, Cell, Tag, Col, Row, Icon } from 'vant';
import Valine from './Valine.vue';

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
// Step 1. Import prismjs
import Prism from 'prismjs';
// Step 2. Import language files of prismjs that you need(后面也可以加java)
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import './toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

export default {
  props: {
    blogDetails: {
      type: Object,
      required: true
    }
  },
  watch: {
    blogDetails: {
      handler: function (newVal, oldVal) {
        console.log('blogDetails changed from', oldVal, 'to', newVal);
        this.valineOptions.path = '/blog/' + this.blogDetails.id + '.html'
        this.$nextTick(() => {
          this.$refs.markdownViewer.invoke('setMarkdown', this.blogDetails.content);
        })
      },
      deep: true
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [Sticky.name]: Sticky,
    [Divider.name]: Divider,

    Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },

      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },
    };
  },
  //初始化评论
  mounted () {
    this.valineOptions.path = '/blog/' + this.blogDetails.id + '.html'
  },
  methods: {
    //分享 
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/blog_' + this.blogDetails.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: '本文链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = this.blogDetails.title + ": https://m.golangroadmap.com/#/shareblog?id=" + this.blogDetails.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
.blog-detail-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.31;
}

/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
