<template>
  <div>
    <!-- 头部轮播图 -->
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <div @click="goto(item.url)">
          <van-image radius="5px" :src="item.image" alt="" />
        </div>
      </van-swipe-item>
    </van-swipe>

    <!-- Go视界分类切换 -->
    <van-tabs @click="onClick">
      <van-tab v-for="(item, index) in category" :key="index">
        <template #title>
          <div class="category-item">
            <span>
              {{ item.value }}
            </span>
            <div>
              {{ item.label }}
            </div>
          </div>
        </template>
      </van-tab>
    </van-tabs>
    <van-loading
      style="padding: 90px; 0"
      v-if="loading"
      color="#0094ff"
      size="24px"
      vertical
      >加载中...</van-loading
    >
    <van-empty
      description="欢迎投稿丰富此版块"
      v-if="list.length === 0 && loading === false"
    />

    <van-card
      v-for="(item, index) in fmtData"
      :key="index"
      :origin-price="item.oldPrice"
      :price="item.price"
      :thumb="item.img"
    >
      <template #title>
        <span class="card_title">{{ item.name }}</span>
      </template>
      <template #tags>
        <van-tag plain type="danger">{{ item.tag }}</van-tag>
      </template>
      <template #desc>
        <!-- 最多显示三行 -->
        <div class="card_desc" v-html="item.memo"></div>
      </template>
      <template #footer>
        <van-button type="info" size="mini" :url="item.url"
          >课程详情</van-button
        >
        <van-button type="info" @click="buyClick(item.kttImg)" size="mini"
          >购买课程</van-button
        >
      </template>
    </van-card>

    <van-dialog v-model="popupShow" title="微信扫码下单" show-cancel-button>
      <div style="text-align: center">
        <img style="width: 280px" :src="buyImg" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  Dialog,
  Button,
  Card,
  Loading,
  Tabbar,
  TabbarItem,
  Cell,
  CellGroup,
  Popup,
  Pagination,
  Grid,
  GridItem,
  Image,
  Swipe,
  SwipeItem,
  Col,
  Row,
  Icon,
  Tag,
  Tab,
  Tabs,
  Empty,
} from "vant";

const axios = require("axios");
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    [Card.name]: Card,
    [Loading.name]: Loading,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Pagination.name]: Pagination,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data() {
    return {
      //列表加载效果开关
      loading: false,
      // Go视界分类
      category: [
        { label: "全部", value: "🔵" },
        { label: "Go基础", value: "📚" },
        { label: "Go实战", value: "⚔️" },
        { label: "云原生", value: "☁️" },
        { label: "其他", value: "🛸" },
      ],
      //头部轮播图
      images: [
        {
          image:
            "https://image-1302243118.cos.ap-beijing.myqcloud.com/img/gmstjz_sy_banner.jpg",
          url: "https://mp.weixin.qq.com/s?__biz=Mzg2NzExNzU5MQ==&mid=2247487159&idx=1&sn=098e34b8d768709e7c6acb301216f29d&chksm=ce4131cff936b8d9a6a79a3601731faea24b75fed8dce01e63ba0eb0adade51304dfa5491eab#rd",
        },
        {
          image:
            "https://image-1302243118.cos.ap-beijing.myqcloud.com/img/qzfu_sy_banner.jpg",
          url: "https://www.golangroadmap.com/docs/offer/resume.html",
        },
      ],
      //Go课程全部数据
      list: [],
      //展示数据
      fmtData: [],
      popupShow: false,
      buyImg: "",
    };
  },
  created() {
    this.initData();
  },
  methods: {
    //导航切换
    onClick(name) {
      this.fmtData = this.list.filter((item) => {
        if (name === 0) {
          return item;
        } else if (item.feature.includes(this.category[name].label)) {
          return item;
        }
      });
    },

    initData() {
      this.list = [];
      this.loading = true;
      axios
        .post("https://api.golangroadmap.com/getPracticalCourse")
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data;
            this.fmtData = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    buyClick(val) {
      this.buyImg = val;
      this.popupShow = true;
    },
    goto(val) {
      window.open(val, "_blank");
    },
  },
};
</script>

<style>
.card_desc {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 2px;
}
/* .van-tabs__line {
  background-color: #39a9ed;
} */

.my-swipe .van-swipe-item {
  color: #fff;
  width: 300px;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.blog-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 140px;
  height: 210px;
  margin-right: 8px;
}
.card_title {
  font-size: 14px;
  line-height: 25px;
  color: var(--juejin-font-2);
  margin-top: 4px;
}
</style>