<template>
  <div>
    <!-- 城市切换 -->
    <van-tabs style="padding-top: 4px"
              @click="onClick">
      <van-tab v-for="(item, index) in category"
               :key="index">
        <template #title>
          <div class="category-item">
            <span>
              {{ item.value }}
            </span>
            <div>
              {{ item.label }}
            </div>
          </div>
        </template>
      </van-tab>
    </van-tabs>

    <van-cell-group>
      <van-cell icon="bar-chart-o">
        <template #title>
          公司梯队：<van-tag size="medium"
                   type="primary">{{ levelName }}</van-tag>
        </template>
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="showLeverPicker = true">选择梯队</van-button>
        </template>
      </van-cell>
    </van-cell-group>
    <van-popup v-model="showLeverPicker"
               position="bottom">
      <van-picker title="选择梯队"
                  show-toolbar
                  :columns="levelColumns"
                  @confirm="onLevelConfirm"
                  @cancel="onLevelCancel" />
    </van-popup>
    <van-list v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad">
      <van-row style="background-color: white; border: 1px dashed #ebedf0"
               v-for="(item, index) in list"
               :key="index"
               @click="goto(item.id)">
        <van-col span="6">
          <div style="text-align: center; margin: 5px 0px">
            <img :src="item.imgUrl"
                 alt="Image"
                 width="60px"
                 height="60px"
                 style="
                border: 1px solid #4b96e6a8;
                border-radius: 9px;
                padding: 5px;
              " />
          </div>
        </van-col>
        <van-col span="18">
          <van-cell>
            <template #title>
              <van-row>
                <van-col span="18"
                         style="font-weight: bold">{{ item.name }}
                </van-col>
                <van-col span="6"
                         style="text-align: right">
                  <van-tag size="medium"
                           type="warning">
                    {{item.ranking | rankingFilter}} </van-tag>
                </van-col>
              </van-row>

              <!-- <span class="custom-title">{{ item.name }}</span>
              <van-tag>第一梯队</van-tag> -->
            </template>

            <template #label>
              <div style="margin-top: 10px">
                <van-badge :content="item.postNum"
                           color="#1989fa">
                  <van-tag plain
                           size="large"
                           type="primary"> 招聘 </van-tag>
                </van-badge>
                <van-badge :content="item.interviewNum"
                           color="#1989fa">
                  <van-tag style="margin-left: 10px"
                           plain
                           size="large"
                           type="primary">
                    面经
                  </van-tag>
                </van-badge>
                <van-badge :content="item.pusherNum"
                           color="#1989fa">
                  <van-tag style="margin-left: 10px"
                           plain
                           size="large"
                           type="primary">
                    内推官
                  </van-tag>
                </van-badge>
                <van-badge :content="item.baoliaoNum"
                           color="#1989fa">
                  <van-tag style="margin-left: 10px"
                           plain
                           size="large"
                           type="primary">
                    薪酬爆料
                  </van-tag>
                </van-badge>
              </div>
            </template>
          </van-cell>
        </van-col>
      </van-row>
    </van-list>
  </div>
</template>

<script>
import {
  Picker,
  Badge,
  NoticeBar,
  Tabs,
  Tab,
  Popup,
  NavBar,
  Image as VanImage,
  Button,
  RadioGroup,
  Radio,
  Form,
  Field,
  Toast,
  Search,
  DropdownMenu,
  DropdownItem,
  List,
  Cell,
  CellGroup,
  Tag,
  Icon,
  Col,
  Row,
  Rate,
  Switch,
  Grid,
  GridItem,
} from "vant";
const axios = require("axios");

export default {
  components: {
    [Picker.name]: Picker,
    [Badge.name]: Badge,
    [NoticeBar.name]: NoticeBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [VanImage.name]: VanImage,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Rate.name]: Rate,
    [Switch.name]: Switch,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },

  data () {
    return {
      // Go公司分类
      category: [
        // { label: "全部", value: "🔵" },
        { label: "北京", value: "📚" },
        { label: "上海", value: "📦" },
        { label: "广州", value: "🗳️" },
        { label: "深圳", value: "🕹️" },
        { label: "杭州", value: "📰" },
        { label: "西安", value: "🚀" },
        { label: "南京", value: "🕸️" },
        { label: "武汉", value: "🔗" },
        { label: "成都", value: "⚔️" },
        { label: "苏州", value: "🧰" },
        { label: "郑州", value: "🎭" },
        { label: "重庆", value: "☁️" },
        { label: "长沙", value: "🏦" },
        { label: "厦门", value: "🏄" },
        { label: "天津", value: "🍱" },
        { label: "福州", value: "🍚" },
        { label: "合肥", value: "🀄" },
        { label: "东莞", value: "⛲" },
        { label: "济南", value: "🛸" },
        { label: "远程", value: "🌐" },
      ],
      //梯队选择
      showLeverPicker: false,
      levelColumns: ['全部', '第一梯队', '第二梯队', '第三梯队', '外企', '其他'],
      levelName: "全部",
      list: [],

      //查询条件form
      queryform: {
        city: "北京",
        ranking: 0,
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      finished: false,
    };
  },

  filters: {
    rankingFilter (val) {
      switch (val) {
        case 0:
          return "暂未分级";
        case 1:
          return "第一梯队";
        case 2:
          return "第二梯队";
        case 3:
          return "第三梯队";
        case 4:
          return "外企";
        default:
          return "其他";
      }
    },
  },

  created () {
    this.initData();
  },
  methods: {
    goto (val) {
      console.log(val);
      this.$router.push("/company?id=" + val);
    },
    //梯队筛选
    onLevelConfirm (value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      this.queryform.ranking = index;
      this.search();
      this.showLeverPicker = false;
      this.levelName = value;
    },

    onLevelCancel () {
      this.showLeverPicker = false;
    },

    //导航切换
    onClick (name) {
      console.log(name);
      switch (name) {
        case 0:
          this.queryform.city = "北京";
          break;
        case 1:
          this.queryform.city = "上海";
          break;
        case 2:
          this.queryform.city = "广州";
          break;
        case 3:
          this.queryform.city = "深圳";
          break;
        case 4:
          this.queryform.city = "杭州";
          break;
        case 5:
          this.queryform.city = "西安";
          break;
        case 6:
          this.queryform.city = "南京";
          break;
        case 7:
          this.queryform.city = "武汉";
          break;
        case 8:
          this.queryform.city = "成都";
          break;
        case 9:
          this.queryform.city = "苏州";
          break;
        case 10:
          this.queryform.city = "郑州";
          break;
        case 11:
          this.queryform.city = "重庆";
          break;
        case 12:
          this.queryform.city = "长沙";
          break;
        case 13:
          this.queryform.city = "厦门";
          break;
        case 14:
          this.queryform.city = "天津";
          break;
        case 15:
          this.queryform.city = "福州";
          break;
        case 16:
          this.queryform.city = "合肥";
          break;
        case 17:
          this.queryform.city = "东莞";
          break;
        case 18:
          this.queryform.city = "济南";
          break;
        case 19:
          this.queryform.city = "远程";
          break;
        default:
          this.queryform.city = "北京";
      }

      this.search();
    },

    //初始化公司数据
    initData () {
      axios
        .get(
          "https://api.golangroadmap.com/gopherclub/companies?city=" +
          this.queryform.city +
          "&ranking=0&currentPage=1&pageSize=10"
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data.clubCompanies;
          } else {
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search () {
      this.queryform.pageNo = 1;
      axios
        .get(
          "https://api.golangroadmap.com/gopherclub/companies?city=" +
          this.queryform.city +
          "&ranking=" + this.queryform.ranking + "&currentPage=1&pageSize=10"
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data.clubCompanies;

            this.total = response.data.data.count;

            if (this.list.length >= this.total) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          } else {
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onLoad () {
      this.queryform.pageNo = this.list.length / 10 + 1;
      axios
        .get(
          "https://api.golangroadmap.com/gopherclub/companies?city=" +
          this.queryform.city +
          "&ranking=" + this.queryform.ranking + "&pageSize=10&currentPage=" +
          this.queryform.pageNo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            // this.list = response.data.data.clubCompanies

            this.total = response.data.data.count;
            for (var i = 0; i < response.data.data.clubCompanies.length; i++) {
              this.list.push(response.data.data.clubCompanies[i]);
            }
            //    加载状态结束
            this.loading = false;
            //全部加载完毕
            console.log("len:" + this.list.length);
            console.log(this.total);
            if (this.list.length >= this.total) {
              this.finished = true;
            }
          } else {
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onConfirm () {
      this.$refs.item.toggle();
      this.search();
    },
  },
};
</script>

<style lang="less">
</style>
