<template>
  <div>
    <van-nav-bar right-text="分享"
                 @click-right="onClickRight">
      <template #left>
        <van-image radius="18px"
                   height="40px"
                   width="40px"
                   @click="txClick"
                   src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
      </template>
      <template #title>
        <span style="font-weight: 600; color: #0af">知识星球·微信端</span>
      </template>
    </van-nav-bar>

    <van-tabs sticky
              v-model="active">
      <van-tab title="推荐">
        <recommend @tabChange="handleTabChange" />
      </van-tab>
      <van-tab title="Go视界">
        <video-list />
      </van-tab>
      <van-tab title="Go博文">
        <blog-list />
      </van-tab>
      <van-tab title="Go合集">
        <collection-list />
      </van-tab>
      <van-tab title="面试宝典">
        <interview-guide />
      </van-tab>

      <van-tab title="Go课程">
        <class-list />
      </van-tab>
      <!-- <van-tab title="Go面经">Go面经</van-tab> -->
      <van-tab title="Go岗位">        
        <post-list />
      </van-tab>
      <van-tab title="Go公司">
        <!-- <van-empty description="专区待开放" /> -->
        <company-list />
      </van-tab>
    </van-tabs>

    <router-view />

    <!-- <van-tabbar route>
      <van-tabbar-item replace to="/home" icon="home-o">社区</van-tabbar-item>
      <van-tabbar-item replace to="/post" icon="search">职位</van-tabbar-item>
      <van-tabbar-item replace to="/post" icon="search">消息</van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="search">我</van-tabbar-item>
    </van-tabbar> -->
    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />
  </div>
</template>

<script>
import {
  Empty,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  ImagePreview,
  Dialog,
  ShareSheet,
  Form,
  Field,
  Icon,
  Button,
  NoticeBar,
  Image as VanImage,
  Col,
  Row,
  NavBar,
  Grid,
  GridItem,
} from "vant";
import Recommend from "../../components/Recommend.vue";
import VideoList from "../../components/VideoList.vue";
import BlogList from "../../components/BlogList.vue";
import ClassList from "../../components/ClassList.vue";
import InterviewGuide from "../../components/InterviewGuide.vue";
import PostList from "../../components/PostList.vue";
import CollectionList from "../../components/CollectionList.vue";
import CompanyList from '../../components/CompanyList.vue';

export default {
  components: {
    Recommend,
    CollectionList,
    VideoList,
    BlogList,
    ClassList,
    PostList,
    CompanyList,

    InterviewGuide,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [ImagePreview.Component.name]: ImagePreview.Component,

    [Dialog.Component.name]: Dialog.Component,
    [ShareSheet.name]: ShareSheet,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,

    [Col.name]: Col,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
  },
  data () {
    return {
      active: 0,
      showShare: false,
      shareOptions: [
        { name: "微信", icon: "wechat" },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        {
          name: "专属海报",
          icon: "https://img01.yzcdn.cn/vant/custom-icon-light.png",
        },
        // { name: '二维码', icon: 'qrcode' },
      ],
    };
  },
  mounted () {
    const tab = this.$route.query.tab;
    if (tab) {
      this.active = parseInt(tab);
    }
  },
  methods: {
    //点击头像进入服务页
    txClick () {
      this.$router.push("user");
    },
    //子组件触发tab切换
    handleTabChange (tabIndex) {
      this.active = tabIndex;
    },
    //打开分享
    onClickRight () {
      this.showShare = true;
    },
    //分享
    onSelect (shareOption) {
      if (shareOption.name == "专属海报") {
        var inviteImage =
          "https://image-1302243118.cos.ap-beijing.myqcloud.com/invite/" +
          window.localStorage.getItem("invitationCode") +
          ".jpg";

        ImagePreview({
          images: [inviteImage],
          closeable: true,
        });

        this.showShare = false;
      } else {
        Dialog({
          message: "分享信息已复制到剪切板，前往微信分享给朋友吧！",
          theme: "round-button",
          confirmButtonColor: "#0af",
        });
        this.showShare = false;
        let container = this.$refs.container;
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl =
          "GOLANG ROADMAP一个专注Go语言学习、求职的社区：https://m.golangroadmap.com/#/login?code=" + invitationCode;
        this.$copyText(shareUrl, container);
      }
    },
  },
};
</script>

<style >

</style>
