<template>
  <div style="background-color: white;">
    <van-sticky :offset-top="50">
      <van-cell>
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-row>
            <van-col span="18"
                     style="font-weight: bold;">
              <van-tag plain
                       type="danger"
                       v-if="postDetail.urgentStatus === '1'">急</van-tag> {{postDetail.post}}
            </van-col>
            <van-col span="6"
                     style="text-align: right;color: brown;">{{postDetail.salary}}</van-col>
          </van-row>

          <van-row>
            <van-col span="14">
              {{postDetail.company}}
            </van-col>
            <!-- <van-col v-if="postDetail.bonus"
                     span="10"
                     style="text-align: right;color: red;">推荐入职得：{{postDetail.bonus}}</van-col> -->
          </van-row>

        </template>

        <template #label>

          <van-row type="flex"
                   gutter="2">
            <van-col span="3"><van-tag size="medium"
                       type="warning">{{postDetail.postCity}}</van-tag></van-col>
            <van-col span="3"><van-tag size="medium"
                       type="primary">{{postDetail.education}}</van-tag></van-col>
            <van-col span="18">
              <div style="overflow: hidden;height: 18px; text-align: right;">{{postDetail.address}}</div>
            </van-col>
          </van-row>

          <van-row type="flex">

            <van-col span="24"
                     style="text-align: right;"> <van-button type="info"
                          size="mini"
                          @click="onClickShare">分享岗位</van-button></van-col>

          </van-row>

        </template>
      </van-cell>
    </van-sticky>
    <!-- <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"></van-divider> -->
    <!-- <van-row type="flex"
             justify="center">
      <van-col span="22">
        <iframe :src="postDetail.videoUrl"
                width="100%"
                height="220"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"> </iframe>

      </van-col>
    </van-row> -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      职位描述
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="postDetail.detail != ''"
             class="markdown-body">
          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="postDetail.detail"></viewer>
        </div>
        <div v-else>
          <van-empty description="岗位详情整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      投递及咨询
    </van-divider>
    <div style="background:#fff"
         align="center">
      <van-image fit="scale-down"
                 width="240px"
                 src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/temp122.png">
        <!-- <template v-slot:loading>
          <van-loading type="spinner"
                       size="20"
                       color="#1989fa">加载中...</van-loading>
        </template> -->
      </van-image>
    </div>
    <!-- <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row> -->
    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Button, Dialog, ShareSheet, Sticky, Divider, Empty, Cell, Tag, Col, Row, Image as VanImage, } from 'vant';
// import Valine from './Valine.vue';

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
// Step 1. Import prismjs
import Prism from 'prismjs';
// Step 2. Import language files of prismjs that you need(后面也可以加java)
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import './toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

export default {
  props: {
    postDetail: {
      type: Object,
      required: true
    }
  },
  watch: {
    postDetail: {
      handler: function (newVal, oldVal) {
        console.log('postDetail changed from', oldVal, 'to', newVal);
        // this.valineOptions.path = '/post/' + this.postDetail.id + '.html'
        this.$nextTick(() => {
          this.$refs.markdownViewer.invoke('setMarkdown', this.postDetail.detail);
        })
      },
      deep: true
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Button.name]: Button,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [VanImage.name]: VanImage,
    [Divider.name]: Divider,
    [Sticky.name]: Sticky,
    // Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },

      // valineOptions: {
      //   appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
      //   appKey: 'nscorEe7TaRyn1DBFNicKsWH',
      //   placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
      //   path: window.location.pathname,
      // },
    };
  },
  methods: {

    //分享
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {        
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/post_' + this.postDetail.id + '.jpg'

        const img = new Image();        
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: '岗位信息链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = "这里有一个靠谱的Go岗位分享给你：https://m.golangroadmap.com/#/sharepost?id=" + this.postDetail.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },

    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
